@import 'variables';

html {
    scroll-behavior: smooth;
}

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.sticky {
    position: fixed;
    left: 0;
    right: 0;
}

body {
    font-family: var(--primary-font-family);
    font-weight: 300;
    color: var(--color-grayscale-9);
    line-height: 1.7;
}

.text-primary {
    color: var(--color-grayscale-9);
}

.cms-content {
    line-height: 1.7;
}

.cms-content p {
    line-height: 1.7;
    margin: 1.6rem 0;
}

.cms-content ul {
    margin: 16px 0;
    padding-left: 2rem;
}

.cms-content ul li {
    line-height: 1.7;
    margin: .5rem 0;
}

.cms-content h1,
.cms-content h2 {
    line-height: 1.7;
    font-size: 26px;
    font-weight: 400;
    padding: 4px 0 10px 0;
}

.cms-content strong {
    font-size: 16px;
    line-height: 1.7;
    font-weight: 700;
}

.cms-content h3 {
    font-size: 16px;
    line-height: 2;
    margin-top: 25px;
    font-weight: 700;
}

.cms-content blockquote {
    border-left: 2px solid var(--color-grayscale-2);
    padding-left: 1rem;
    line-height: 1.5;
    margin: 2rem 0;
    font-weight: normal;
}

.cms-content {
    a {
        text-decoration: none;
        color: var(--color-grayscale-9);
        position: relative;
        cursor: pointer;

        &:after {
            content: '';
            position: absolute;
            bottom: -3px;
            right: 0;
            left: 0;
            border-bottom: 1px dashed var(--color-grayscale-4);
        }

        &:hover:after {
            border-bottom: 1px solid var(--color-grayscale-4);
        }
    }
}

.how-does-it-work {
    background-color: var(--color-grayscale-1);
    line-height: 1.6;
}

.call-to-action {
    background-color: var(--color-orange);
    line-height: 40px;
    transition: background-color 300ms ease-out;

    &:hover {
        background: var(--color-orange-darker);
    }
}

.navigation-link {
    border-bottom: 3px solid transparent;

    &:hover {
        text-decoration: underline;
    }
}

.active {
    border-bottom-color: var(--cement);
}

.sticky-nav {
    position: -webkit-sticky;
    position: sticky;
    top: 16px;
    z-index: 999;
}

@media (max-width: 767px) {
    .page-scrolled {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 999;
    }
}

@media (min-width: 992px) {
    .form-title {
        font-size: 32px;
    }
    .header-image {
        height: 380px;
    }

    .main-logo {
        height: 63px;
    }

    .special-bg:before {
        content: '';
        position: fixed;
        display: block;
        top: 0;
        bottom: 0;
        background-color: var(--color-grayscale-1);
        height: 100vh;
        width: 100vw;
        z-index: -1;
    }

    .navigation-link {
        margin-top: -1px;
        border: none;
        border-top: 1px solid var(--color-grayscale-2);
        border-bottom: 1px solid var(--color-grayscale-2);
        border-left: 3px solid transparent;
    }

    .navigation-link:first-child {
        margin-top: 0;
    }

    .active {
        font-weight: bold;
        border-left-color: var(--color-cement);
    }

    .lg\:container {
        max-width: 768px;
    }

    .lg\:border-t-grey-light {
        border-top-color: #dae1e7;
    }
    .lg\:border-b-grey-light {
        border-bottom-color: #dae1e7;
    }

    .lg\:container {
        max-width: 992px;
    }
}

@media (min-width: 1200px) {
    .lg\:container {
        max-width: 1200px;
    }
}

.radio {
    position: relative;
    display: flex;
    line-height: 2em;
    align-items: center;
    align-content: center;
}

.radio input[type=radio] {
    position: absolute;
    visibility: hidden;
}

.radio label {
    display: block;
    position: relative;
    font-weight: 300;
    font-size: 1.2em;
    padding: 0 0 0 2em;
    z-index: 9;
    cursor: pointer;
    transition: all 0.25s linear;
}

.radio .check {
    display: flex;
    position: absolute;
    border: 1px solid var(--color-grayscale-3);
    border-radius: 100%;
    height: 1.2em;
    width: 1.2em;
    top: 50%;
    transform: translateY(-50%);
    left: 0px;
    z-index: 5;
    align-items: center;
    justify-content: center
}

.radio .check::before {
    display: inline-block;
    content: '';
    border-radius: 100%;
    height: 50%;
    width: 50%;
    transition: background 0.25s linear;
}

input[type=radio]:checked ~ .check {
    border: 1px solid var(--color-cement);
}

input[type=radio]:checked ~ .check::before {
    background: var(--color-cement);
}

input[type=radio]:checked ~ label {
    color: var(--color-cement);
}

input[type=text] {
    border: 1px solid var(--color-grayscale-3);
    border-radius: 0;
    line-height: 40px;
    transition: border-color .3s ease;
}

input[type=text]:focus {
    border-color: var(--color-cement);
    outline: none;
}

@media(max-width: 1024px) {
    :target::before {
        content: " ";
        width: 100px;
        display: block;
        margin-top: 85px;
    }
}
